<template>
  <div style="width: 100%; height: 65vh;">
    <div v-if="!detected">
      <qrcode-stream @detect="onDetect"></qrcode-stream>
    </div>
    <div v-else style="display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;">
      <img v-if="checked" src="./assets/check.png" width="125px" />
      <h2 v-if="checked">{{product}} validada correctamente</h2>
      <img v-if="erro" src="./assets/alert.png" width="125px" />
      <h2 v-if="erro">No se pudo validar la entrada<br>{{ errm }}</h2>
      <button class="rounded-button" @click="resetvalues">Escanear entradas</button>
    </div>
  </div>


</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import axios from "axios";
export default {
  name: 'App',
  components: {
    QrcodeStream
  },
  data() {
    return {
      detected: false,
      detectedn: '',
      checked: false,
      erro: false,
      errm: '',
      product: ''
    }
  },
  methods: {
    onDetect(detectedCodes) {
      this.checked = false;
      this.erro = false;
      this.detected = true;
      axios.post('https://vineadevaia.com/confirmarentrada.php', { code: detectedCodes[0].rawValue }).then(response => {
        this.product = response.data.producto
        if (response.data.producto == 'Entrada gratuita')
          document.body.style.backgroundImage = 'url("https://vineadevaia.com/images/devaia_entradapdf_gratuita.jpg")'; // Cambia esta URL al fondo deseado
        else if (response.data.producto == 'Entrada General')
          document.body.style.backgroundImage = 'url("https://vineadevaia.com/images/devaia_entradapdf_general.jpg")'; // Cambia esta URL al fondo deseado
        else if (response.data.producto == 'Entrada + 20€ Tokens')
          document.body.style.backgroundImage = 'url("https://vineadevaia.com/images/devaia_entradapdf_tokens.jpg")'; // Cambia esta URL al fondo deseado
        else if (response.data.producto == 'Invitación')
          document.body.style.backgroundImage = 'url("https://vineadevaia.com/images/vineadevaia_invitacio.jpg")'; // Cambia esta URL al fondo deseado
          document.body.style.backgroundSize = 'contain'
          document.getElementById('app').style.backgroundColor = '#ffffffc7'; 
          this.checked = true
      }).catch(err => {
        document.getElementById('app').style.backgroundColor = '#ffffffc7'; 
        this.erro = true
        this.errm = err.response.data.err
      })
    },
    resetvalues() {
      this.checked = false
      this.erro = false
      this.detected = false
      this.product = ''
      this.errm = ''
      document.getElementById('app').style.backgroundColor = 'transparent'; 
      document.body.style.backgroundSize = 'cover'
      document.body.style.backgroundImage = 'url("https://vineadevaia.com/images/webp/devaia_hero_bg.webp")'; // Cambia esta URL al fondo deseado
    }
  }
}
</script>

<style>
body {
  margin: 0;
  background-image: url("https://vineadevaia.com/images/webp/devaia_hero_bg.webp");
  background-size: cover; /* Asegura que el fondo cubra todo el espacio */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-position: center; 
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 50px 0;
  margin: 50px 0;
  background-color: transparent; /* Color de fondo por defecto */
  transition: background-color 0.5s; /* Añade una transición suave */

}

.rounded-button {
  background-color: #6cc9dd;
  color: #2c3e50;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}
</style>
